import React from "react";
import Home from "./page";
import "antd/dist/antd.css";

const App = () => (
  <>
    <Home />
  </>
);

export default App;
